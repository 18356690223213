<template>
  <!-- 试题列表 -->
  <div>
    <div class="paper">
      <ul v-for="(item, index) in paperlist" :key="index">
        <li>
          <el-row :gutter="20">
            <el-col :span="10"
              ><div class="grid-content bg-purple">
                题号 : {{ item.question_id }}
              </div></el-col
            >
            <el-col :span="6"
              ><div class="grid-content bg-purple">
                题型 : {{ item.question_type }}
              </div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">
                难度 : {{ item.question_level }}
              </div></el-col
            >
          </el-row>
        </li>
        <li @click="jiexi(index)" class="clearfix">
          <div v-html="item.question_text"></div>
          <div v-html="item.options_json" style="margin-top:20px"></div>
        </li>
        <li class="clearfix">
          <div class="right fr">
            <div class="scqh fl">
              <span
                class=""
                @click="shoucan(1, item, index)"
                :class="shoucangflag(shoucanlist,index) ? 'active' : ''"
                >收藏</span
              >
              <span
                class=""
                @click="shoucan(2, item, index)"
                :class="shoucangflag(shoucanlist,index) ? '' : 'active'"
                >移除收藏</span
              >
            </div>
            <div class="jrqh fr">
              <span
                class="one"
                :class="joinflag(jiarulist,index) ? 'active' : ''"
                @click="joinParper(item, 1, index)"
                >加入试卷</span
              >
              <span
                class="two"
                :class="joinflag(jiarulist,index) ? '' : 'active'"
                @click="joinParper(item, 2, index)"
                >移出试卷</span
              >
            </div>
          </div>
        </li>
        <li v-show="status[index].id == 2">
          <div v-html="item.answer"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueEvent from "@/api/eventBus.js";
import URL from "@/api/index";
export default {
  props: ["paperlist"],
  data() {
    return {
      // 已加入试卷试题集合
      topiclist: [],

      // 一加入试卷试题集合 状态判断
      jiarulist: [],

      // 收藏集合
      shoucanlist: [],

      status: [],

      token: "",

      // 数据上报字段
      client_ip:'',

    };
  },
  created() {
    // 已加入试卷的试题集合
    if (JSON.parse(localStorage.getItem("shiti"))) {

      // 题型集合
      var questionslist  = Object.keys(JSON.parse(localStorage.getItem("shiti")))

      // 试题集合
      var topic = []
      questionslist.forEach(function (res) {
        JSON.parse(localStorage.getItem("shiti"))[res].forEach(function (e) {
          topic.push(e)
        });
      });

      // 已加入试卷所有试题
      this.topiclist = topic
    }
  },
  watch: {
    paperlist(val, old) {

      //试题加入试卷保存状态
      this.status = []
      for (var i = 0; i <= val.length; i++) {
        this.status.push({ id: 1 })
      }

      // 当前试题集合与加入试卷试题集合比对
      if(val != old){
        var that = this
        that.jiarulist = []
        if (JSON.parse(localStorage.getItem("shiti"))) {
          var questionslist = Object.keys(JSON.parse(localStorage.getItem("shiti")))
          val.forEach(function (data, index) {
            questionslist.forEach(function (res) {
              if (data.question_type == res) {
                JSON.parse(localStorage.getItem("shiti"))[res].forEach(function (item) {
                  if (data.id == item.id) {
                    that.jiaruzhuangtai(index)
                  }
                })
              }
            })
          })
        } else {
          this.topiclist = []
          if (old != val) {
            this.jiarulist = []
          }
        }
      }

      // // 当前试题集合与收藏试题集合比对
      if (JSON.parse(localStorage.getItem("collect"))) {
        this.shoucanlist = []
        var that = this
        var collect = JSON.parse(localStorage.getItem("collect"))
        val.forEach(function (data, index) {
          collect.forEach(function (data1) {
            if (data.question_id == data1) {
              that.shouczhuangtai(index)
              return
            }
          })
        })
      }
    },
    jiarulist(val) {
      if (!JSON.parse(localStorage.getItem("paperlist"))) {
        if (val.length == JSON.parse(localStorage.getItem("paperlist")).length) {
          VueEvent.$emit("huifu", false)
        } else {
          VueEvent.$emit("huifu", true)
        }
      }
    },
  },
  mounted() {
    var that = this
    that.token = JSON.parse(localStorage.getItem("loginInfo")).token
    VueEvent.$off("syshiti")
    VueEvent.$on("syshiti", function (data) {
      that.shitilan(data)
    })
    VueEvent.$off("quanbushiti")
    var aa = []
    VueEvent.$on("quanbushiti", function (data) {
      JSON.parse(localStorage.getItem("paperlist")).forEach(function (res) {
        that.topiclist.forEach(function (item, index) {
          if (res.question_id == item.question_id) {
            aa.push(item)
          }
        })
      })
      aa.forEach(function (res) {
        that.topiclist.forEach(function (item, index) {
          if (res.question_id == item.question_id) {
            that.topiclist.splice(index, 1)
          }
        })
      })
      that.jiarulist = []
      data.forEach(function (item, index) {
        that.joinParper(item, 1, index)
      })
    })
    VueEvent.$off("daanzhankai")
    VueEvent.$on("daanzhankai", function (data) {
      data.forEach(function (res, index) {
        that.status[index].id = res.status
        that.jiexi(index)
      })
    })
  },
  methods: {
    //试题篮返回数组状态判断
    shitilan(e) {
      var that = this
      var aa = []
      var aaa = Object.keys(e)
      aaa.forEach(function (res) {
        e[res].forEach(function (item) {
          aa.push(item)
        })
      })
      that.topiclist = aa
      // question_id 题号
      var bb = []
      aa.forEach(function (data) {
        JSON.parse(localStorage.getItem("paperlist")).forEach(function (
          res,
          index
        ) {
          if (data.question_id == res.question_id) {
            bb.push(index)
          }
        })
      })
      this.jiarulist = bb
    },
    //加入试卷状态赋值
    jiaruzhuangtai(index) {
      let hash = this.jiarulist.findIndex((item) => {
        return item == index
      })
      if (hash > -1) {
        this.jiarulist.splice(hash, 1)
      } else {
        this.jiarulist.push(index)
      }
    },
    // 试题收藏状态赋值
    shouczhuangtai(index) {
      let hash = this.shoucanlist.findIndex((item) => {
        return item == index
      })
      if (hash > -1) {
        this.shoucanlist.splice(hash, 1)
      } else {
        this.shoucanlist.push(index)
      }
    },
    shoucangflag(arr,index) {
      const flag = arr.find((item) => {
        return item == index
      })
      if (flag != undefined) {
        return true
      }else{
        return false
      }
    },
    joinflag(arr,index) {
      const flag = arr.find((item) => {
        return item == index
      })
      if (flag != undefined) {
        return true
      }else{
        return false
      }
    },
    // 收藏
    shoucan(e, item, index) {
      var that = this
      var data = {
        token: that.token,
        question_id: item.question_id,
      }

      if (this.$route.path == '/testPaper/FavoriteTopics') {
        // that.uncollectwork(item)
        var collect = JSON.parse(localStorage.getItem("collect"))
        collect.forEach(function (res, index) {
          if (res == item.question_id) {
            collect.splice(index, 1)
          }
        })
        localStorage.setItem("collect", JSON.stringify(collect))

        that.paperlist.forEach(function (data, index) {
          if (data.question_id == item.question_id) {
            that.paperlist.splice(index, 1)
          }
        })
        localStorage.setItem("paperlist", JSON.stringify(that.paperlist))

        that.$emit("num", that.paperlist.length)

        that.shoucanlist = []
        // 删除
        this.$post(URL.DelQuestion, data).then((res) => {
          this.$emit('del','refresh')
        })

      } else {
        var collect = JSON.parse(localStorage.getItem("collect"))
        let hash = this.shoucanlist.findIndex((item) => {
          return item == index
        })
        if (hash > -1) {
          this.shoucanlist.splice(hash, 1)
        } else {
          this.shoucanlist.push(index)
        }
        if (e == 1) {
          // 增加收藏题
          this.$post(URL.AddQuestion, data).then((res) => {
            // that.collectwork(item)
          })
          collect.push(item.question_id)
          localStorage.setItem("collect", JSON.stringify(collect))
        } else {
          // 删除收藏题
          this.$post(URL.DelQuestion, data).then((res) => {
            // that.uncollectwork(item)
          })
          collect.forEach(function (res1, index) {
            if (item.question_id == res1) {
              collect.splice(index, 1)
            }
          })
          localStorage.setItem("collect", JSON.stringify(collect))
        }
      }
    },

    //加入试卷
    joinParper(data, e, index) {
      // 继续挑题 新题型加入
      var listtitle = JSON.parse(localStorage.getItem("listtitle"))
      if (listtitle) {
        var isHave = listtitle.findIndex((item) => {
          return item == data.question_type
        })
        // 没有 就加入题型
        if (isHave == -1) {
          listtitle.push(data.question_type)
          localStorage.setItem("listtitle", JSON.stringify(listtitle))
        }
      }

      // 加入试卷按钮 状态
      let hash = this.jiarulist.findIndex((item) => {
        return item == index
      })
      if (hash > -1) {
        this.jiarulist.splice(hash, 1)
      } else {
        this.jiarulist.push(index)
      }
      
      //试题数组
      var shuju = data
      var that = this

      // splice(index, 1)删除   push()添加   topiclist已加入试卷试题集合
      if (e == 1) {
        that.topiclist.push(shuju)
        // that.joinwork(data)
      } else {
        VueEvent.$emit("huifu", true)
        that.topiclist.forEach(function (res, index) {
          if (res.id == shuju.id) {
            that.topiclist.splice(index, 1)
            // that.unjoinwork(data)
          }
        })
      }

      if (that.topiclist.length == 0) {
        localStorage.removeItem("shiti")
        localStorage.removeItem("listtitle")
        localStorage.removeItem("shitilength")
        VueEvent.$emit("shitilength", 0)
      } else {
        that.fenzu(that.topiclist)
      }

      // 判断 移出试卷 是否还有该类大题
      var shiti = JSON.parse(localStorage.getItem("shiti"))
      for (let key in shiti) {
        if (!shiti[data.question_type]) {
          var listtitle = JSON.parse(localStorage.getItem("listtitle"))
          if (listtitle) {
            for (var i = 0; i < listtitle.length; i++) {
              if (listtitle[i] == data.question_type) {
                listtitle.splice(i, 1)
                localStorage.setItem("listtitle", JSON.stringify(listtitle))
              }
            }
          }
        }
      }
    },

    //数据分组
    fenzu(arr) {
      localStorage.setItem("shitilength", JSON.stringify(arr.length))
      var types = []
      for (var i in arr) {
        if (types.indexOf(arr[i].question_type) === -1) {
          types.push(arr[i].question_type)
        }
      }
      var obj = {} // 根据type生成多个数组
      for (var k in types) {
        for (var j in arr) {
          if (arr[j].question_type == types[k]) {
            obj[types[k]] = obj[types[k]] || []
            obj[types[k]].push(arr[j])
          }
        }
      }
      localStorage.setItem("shiti", JSON.stringify(obj)) //直接加入试卷
      if (JSON.parse(localStorage.getItem("shiti"))) {
        var sss = JSON.parse(localStorage.getItem("shiti"))
      }
      VueEvent.$emit("shitilength", { a: arr.length, b: obj })
    }, 
    
    //解析
    jiexi(index) {
      if (this.status[index].id == 1) {
        this.status[index].id = 2
        return
      }
      if (this.status[index].id == 2) {
        this.status[index].id = 1
        return
      }
    },
    handleCurrentChange(val) {
      this.$emit("pages", val)
    },

    // 数据上报
    // 收藏
    collectwork(item){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;

        var differentiate = JSON.parse(localStorage.getItem('optionwork'))
        if(differentiate.id == '1'){
          var section_id = !JSON.parse(localStorage.getItem("pre_zjid"))
          var section_name = !JSON.parse(localStorage.getItem("pre_zjname"))
          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
            title_one:JSON.parse(localStorage.getItem('optionwork')).path, //页面路由
            grade_id:localStorage.getItem("zjsidCopy"),
            grade_name:JSON.parse(localStorage.getItem("data_nianji")),
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            section_id:section_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zjid")):' ', //章节id
            section_name:section_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zjname")):' ' //章节
          }
        } else {
          var ability_id = !JSON.parse(localStorage.getItem("pre_zsdid"))
          var ability_name = !JSON.parse(localStorage.getItem("pre_zsdname"))

          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),

            title_one:JSON.parse(localStorage.getItem('optionwork')).path,
            learning_phase:JSON.parse(localStorage.getItem('zjgrade'))==2?"小学":"初中", //学段
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            ability_id:ability_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdid")):' ', //知识点id
            ability_name:ability_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdname")):' ' //知识点
          }
        }
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_collect",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "使用了组卷的收藏功能",
          data: datal,
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    },

    // 移除收藏
    uncollectwork(item){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var differentiate = JSON.parse(localStorage.getItem('optionwork'))
        if(differentiate.id == '1'){
          var section_id = !JSON.parse(localStorage.getItem("pre_zjid"))
          var section_name = !JSON.parse(localStorage.getItem("pre_zjname"))
          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
            title_one:JSON.parse(localStorage.getItem('optionwork')).path, //页面路由
            grade_id:localStorage.getItem("zjsidCopy"),
            grade_name:JSON.parse(localStorage.getItem("data_nianji")),
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            section_id:section_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zjid")):' ', //章节id
            section_name:section_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zjname")):' ' //章节
          }
        } else {
          var ability_id = !JSON.parse(localStorage.getItem("pre_zsdid"))
          var ability_name = !JSON.parse(localStorage.getItem("pre_zsdname"))

          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),

            title_one:JSON.parse(localStorage.getItem('optionwork')).path,
            learning_phase:JSON.parse(localStorage.getItem('zjgrade'))==2?"小学":"初中", //学段
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            ability_id:ability_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdid")):' ', //知识点id
            ability_name:ability_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdname")):' ' //知识点
          }
        }
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_bycollect",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "使用了组卷的移除收藏功能",
          data: datal,
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    },

    // 加入试卷
    joinwork(item){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var differentiate = JSON.parse(localStorage.getItem('optionwork'))
        if(differentiate.id == '1'){
          var section_id = !JSON.parse(localStorage.getItem("pre_zjid"))
          var section_name = !JSON.parse(localStorage.getItem("pre_zjname"))
          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
            title_one:JSON.parse(localStorage.getItem('optionwork')).path, //页面路由
            grade_id:localStorage.getItem("zjsidCopy"),
            grade_name:JSON.parse(localStorage.getItem("data_nianji")),
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            section_id:section_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zjid")):' ', //章节id
            section_name:section_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zjname")):' ' //章节
          }
        } else {
          var ability_id = !JSON.parse(localStorage.getItem("pre_zsdid"))
          var ability_name = !JSON.parse(localStorage.getItem("pre_zsdname"))

          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),

            title_one:JSON.parse(localStorage.getItem('optionwork')).path,
            learning_phase:JSON.parse(localStorage.getItem('zjgrade'))==2?"小学":"初中", //学段
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            ability_id:ability_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdid")):' ', //知识点id
            ability_name:ability_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdname")):' ' //知识点
          }
        }
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_addpapers",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "使用了组卷的加入试卷功能",
          data: datal,
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    },

    // 移除试卷
    unjoinwork(item){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var differentiate = JSON.parse(localStorage.getItem('optionwork'))
        if(differentiate.id == '1'){
          var section_id = !JSON.parse(localStorage.getItem("pre_zjid"))
          var section_name = !JSON.parse(localStorage.getItem("pre_zjname"))
          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
            title_one:JSON.parse(localStorage.getItem('optionwork')).path, //页面路由
            grade_id:localStorage.getItem("zjsidCopy"),
            grade_name:JSON.parse(localStorage.getItem("data_nianji")),
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            section_id:section_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zjid")):' ', //章节id
            section_name:section_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zjname")):' ' //章节
          }
        } else {
          var ability_id = !JSON.parse(localStorage.getItem("pre_zsdid"))
          var ability_name = !JSON.parse(localStorage.getItem("pre_zsdname"))

          var datal = {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),

            title_one:JSON.parse(localStorage.getItem('optionwork')).path,
            learning_phase:JSON.parse(localStorage.getItem('zjgrade'))==2?"小学":"初中", //学段
            subject_id:localStorage.getItem("zjgradeCopy"),
            subject_name:JSON.parse(localStorage.getItem("data_kemu")),
            textbook_id:JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name:JSON.parse(localStorage.getItem("data_zjname")),
            qid:item.question_id,
            qtype:item.question_type,
            complexity:item.level_name,
            ability_id:ability_id == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdid")):' ', //知识点id
            ability_name:ability_name == 'fasle'?JSON.parse(localStorage.getItem("pre_zsdname")):' ' //知识点
          }
        }
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_bypapers",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "使用了组卷的移出试卷功能",
          data: datal,
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    }
  },
}
</script>

<style lang="less" scoped>
.paper {
  margin: 24px 0;
  ul {
    &:hover {
      box-shadow: 0px 0px 6px 0px rgba(84, 84, 84, 0.47);
    }
    box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 0.47);
    background: #fff;
    margin-bottom: 20px;
    padding: 20px 10px;
    li {
      word-wrap: break-word;
      &:first-child {
        border: 1px solid #ccdaee;
        background-color: #e8eef7;
        /deep/ .el-row {
          line-height: 42px;
          font-size: 14px;
          text-indent: 10px;
        }
      }
      &:nth-child(2) {
        padding: 20px 0;
      }
      &:nth-child(3) {
        height: 42px;
        background-color: #f8fafc;
        border: 1px solid #ccdaee;
        padding: 0 10px;
        .left {
          display: inline-block;
          font-size: 14px;
          color: #b8b8b8;
          line-height: 42px;
        }
        .from {
          line-height: 42px;
          font-size: 14px;
          color: #a1a1a1;
          text-align: center;
        }
        .right {
          line-height: 42px;
          font-size: 14px;
          color: #1676ff;
          text-align: center;
          .scqh {
            padding-right: 39px;
            cursor: pointer;
            width: 4em;
          }
          .jrqh {
            .one {
              background-color: #3587fc;
              color: #fff;
              padding: 6px;
            }
            .two {
              padding: 5px;
              border: solid 1px #1676ff;
            }
            span {
              border-radius: 5px;
              height: 30px;
              line-height: 30px;
              cursor: pointer;
            }
          }
        }
      }
      &:nth-child(4) {
        padding: 20px 0 0;
      }
    }
  }
}
.active {
  display: none;
}
.zanwu {
  text-align: center;
  line-height: 100px;
  font-size: 24px;
}
/deep/ .el-pagination {
  white-space: nowrap;
  color: #303133;
  font-weight: 700;
  width: 50%;
  margin: 0 auto;
}
// /deep/ .el-loading-spinner{
//   top: 200px;
// }
.aaa {
  display: block;
}
.bbb {
  display: none;
}
</style>
